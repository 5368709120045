<template>
  <div
    class="content-section blank-aside-js"
    :class="isOpenAside == 'true' ? 'blank-aside' : ''"
  >
    <banner-section location="shaniv_package_details-top"/>

    <section class="main-content-part mb-25">
      <div class="product-part">
        <img src="/new-assets/img/shaniv-logo.png" alt="" class="product-logo mb-2" />
        <div v-for="(package_item, index) in package_data.products" :key="index" class="mb-20">
          <div class="product-content d-flex">
            <img
              :src="package_item.image || '/new-assets/img/shaniv-logo.png'"
              alt=""
              class="product-img"
            />
            <div class="mx-10">
              <h2 class="product-title color-red7">
                {{ package_item.ShanivProduct[currentLang + '_name'] }}
              </h2>
              <!-- <p class="mb-15">
                <span class="color-red1">short description - from our management</span>
              </p>
              <p class="mb-5">
                <b>{{ $t('User manual') }}</b
                >: <span class="color-red1">extended description (display if available) -from our management</span>
              </p> -->
              <p class="mb-5 fs16">
                <b>{{ $t('Content') }}</b
                >: {{ package_item.ShanivProduct.SPEC13 }}
              </p>
              <p class="mb-5 fs16">
                <b>{{ $t('Number of units in the package') }}</b
                >: {{ package_item.ShanivProduct.qtySteps > 0 ? package_item.ShanivProduct.qtySteps : 1 }}
              </p>
              <!-- <p class="mb-5 fs16">
                <b>{{ $t('Number of units per surface') }}</b
                >: {{ package_item.ShanivProduct.FSHA_CARTONINLAYER }}
              </p> -->
              <p class="mb-5 fs16">
                <b>{{ $t('SKU') }}</b
                >: {{package_item.ShanivProduct.PARTNAME}}
              </p>
              <p class="fs16">
                <b>{{ $t('Barcode') }}</b
                >: {{package_item.ShanivProduct.BARCODE}}
              </p>
            </div>
          </div>
          <div class="m-product-content d-flex p-relative">
            <h2 class="product-title text-center color-red7 mb-10">{{ package_item.ShanivProduct[currentLang + '_name'] }}</h2>
            <div class="d-flex mb-10">
              <img
                :src="package_item.image || '/new-assets/img/shaniv-logo.png'"
                alt=""
                class="product-img"
              />
            </div>
            <div>
              <p class="mb-5 fs16">
                <b>{{ $t('Content') }}</b
                >: {{ package_item.ShanivProduct.SPEC13 }}
              </p>
              <p class="mb-5 fs16">
                <b>{{ $t('Number of units in the package') }}</b
                >: {{ package_item.ShanivProduct.qtySteps > 0 ? package_item.ShanivProduct.qtySteps : 1 }}
              </p>
              <p class="mb-5 fs16">
                <b>{{ $t('SKU') }}</b
                >: {{package_item.ShanivProduct.PARTNAME}}
              </p>
              <p class="fs16">
                <b>{{ $t('Barcode') }}</b
                >: {{package_item.ShanivProduct.BARCODE}}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="cart-part mt-20">
        <div class="mt-10">
          <div class="bg-white">
            <div class="package-carousel-header weight-700">
              {{ $t("Package offer") }}: <span class="color-red5 mx-5">{{ $t("Save") }} {{ package_data.DISCOUNT_PRICE }}₪</span>
            </div>
            <img :src="package_data.image" class="package-image" />
            <div class="package-action-block">
              <button class="primary-btn cart-btn" @click='addPackageToCart(package_data)'>
                <img
                  src="/new-assets/icon/shopping-cart-light.svg"
                  alt=""
                  class="mx-5"
                />
                <p>{{ $t('Add to cart the promotion') }}</p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
		<section class="m-footer">
			<div>
        <p><b>₪ 288</b></p>
        <p>{{ $t('Before discount') }}</p>
      </div>
      <button 
        class="primary-btn bg-blue add-basket_btn"
      >
        <img
          src="/new-assets/icon/shopping-cart-light.svg"
          alt=""
          class="mx-2"
        />
        <p>{{ $t('Add basket') }}</p>
      </button>
		</section>
    <!-- ------------More detail modal-------------- -->
    <CartModal v-on:closeModal="closeCartModal()" :cartModalData="cartModalData" :isPackageModal="true" :key="domReload"></CartModal>
		<loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import  CartModal from "../../components/CartModal";
import BannerSection from "../../components/BannerSection";
export default {
  name: 'SastechPackage',
  components: {
    BannerSection,
    CartModal,
  },
  data() {
    return {
      productNum: 1,
      domReload: true,
      cartModalData: {}
    };
  },
	computed: {
    ...mapState({
      loading: (state) => state.shaniv_management.loading,
      package_data: (state) => state.shaniv_management.package_data
    }),
    ...mapGetters('cartSystem', {
      cartTotalDiscountVATPrice: 'cartTotalDiscountVATPrice',
      cartTotalUniqueCount: 'cartTotalUniqueCount'
    }),
    packageId() {
      return this.$route.params.id;
    },
  },
  methods: {
    ...mapActions('cartSystem', {
      addProductsToCart: 'addProductsToCart'
    }),
    ...mapActions('shaniv_management', {
      getPackageData: 'getPackageData'
    }),
    createOpenModal(product, quantity) {
      this.cartModalData = product;
      this.cartModalData.added_quantity = quantity;
      this.cartModalData.added_total_price = this.calculateProductQuantityPrice({   
        DISCOUNT_VATPRICE: this.cartModalData.DISCOUNT_VATPRICE,
        quantity: this.cartModalData.added_quantity,
        qtySteps: this.cartModalData.qtySteps
      });
      this.cartModalData.package_price = this.getProductPackagePrice(product);
      this.cartModalData.package_discount_price = this.getProductPackageDiscountPrice(product); 
      this.cartModalData.total_price = this.cartTotalDiscountVATPrice; 
      this.cartModalData.total_unique_count = this.cartTotalUniqueCount;
      this.cartModalData = JSON.parse(JSON.stringify(this.cartModalData));
      this.domReload = +new Date();
      setTimeout(() => {
        this.openCartModal();
      }, 100);
    },
    addPackageToCart(product) {
      let quantity = 1;
      this.addProductsToCart({
        product,
        quantity,
        company: product.company,
        type: 'packages',
      });

      this.createOpenModal(product, quantity);
    },
    onIncreaseQuantity() {
      this.productNum += 1;
    },
    onDecreaseQuantity() {
      if (this.productNum > 1) {
        this.productNum -= 1;
      }
    },
    /* <!-- ------------cart popup-------------- --> */
    openCartModal() {
      this.$modal.show("cartModal");
    },
    closeCartModal() {
      this.$modal.hide("cartModal");
    },
    calculateProductQuantityPrice({ DISCOUNT_VATPRICE, quantity, qtySteps }) {
      qtySteps = qtySteps && qtySteps > 0 ? qtySteps : 1;

      return Math.round((DISCOUNT_VATPRICE * quantity * qtySteps + Number.EPSILON) * 100) / 100;
    },
    getProductPackageDiscountPrice({ id, qtySteps, DISCOUNT_VATPRICE }) {
      qtySteps = qtySteps && qtySteps > 0 ? qtySteps : 1;

      return Math.round((DISCOUNT_VATPRICE * qtySteps + Number.EPSILON) * 100) / 100;
    },
    getProductPackagePrice({ id, qtySteps, DISCOUNT_VATPRICE, VATPRICE }) {
      VATPRICE = VATPRICE >= DISCOUNT_VATPRICE ? VATPRICE : DISCOUNT_VATPRICE;
      qtySteps = qtySteps && qtySteps > 0 ? qtySteps : 1;

      return Math.round((VATPRICE * qtySteps + Number.EPSILON) * 100) / 100;
    }
  },
  created() {
    this.getPackageData({id: this.packageId});
  }
};
</script>
<style lang="scss" scoped>
  .package-image {
    width: 100%;
  }
  .package-carousel-header {
    padding: 10px;
    text-align: center;
    font-size: 18px;
    border-bottom: 1px solid #707070;
  }
  .package-action-block {
    padding: 5px 15px;
    .cart-btn {
      height: 40px;
      border-radius: 5px;
    }
  }
.content-section {
  margin-top: 20px;
  padding: 0 50px;
	width: 100%;
  @media screen and (max-width: 930px) { 
		padding: 0 20px;
	}
  .plus {
    font-size: 50px;
  }
  .product-image_group {
    justify-content: space-around;
    img {
      width: 80px;
    }
  }
  .main-content-part {
    display: flex;
    justify-content: space-between;
    margin-top: 0;
    padding-bottom: 20px;
		@media screen and (max-width: 930px) { 
			margin-top: 0;
		}
    // border-bottom: 1px solid #898989;
    .product-part {
      padding-left: 50px;
			@media screen and (max-width: 930px) { 
				padding-left: 0;
			}
      .product-logo {
        height: 32px;
				@media screen and (max-width: 930px) { 
					display: none;
				}
      }
      .product-content {
        align-items: flex-start;
				@media screen and (max-width: 930px) { 
					display: none;
				}
        .product-img {
          object-fit: contain;
          width: 120px;
          min-width: 120px;
          margin: 0 10px;
        }
        .product-title {
          font-size: 24px;
          margin-bottom: 10px;
        }
      }
    }

    .cart-part {
      max-width: 450px;
      width: 100%;
      padding: 0 50px;
      .cart-block {
        background-color: #d5d5d5;
        font-size: 16px;
        font-weight: bold;
        width: 100%;
        height: -moz-fit-content;
        height: fit-content;
        .price {
          margin-left: 20px;
          margin-right: 10px;
          display: flex;
          align-items: center;
        }
        .quantity-dropdown {
          width: 82px;
          height: 36px;
          .vs__selected {
            height: 100%;
            top: 0;
          }
          .vs__dropdown-menu {
            min-width: 70px;
            max-width: 70px;
          }
          .vs__search {
            padding: 0;
          }
        }
        .primary-btn {
          height: 40px;
          border-radius: 5px;
					@media screen and (max-width: 930px) { 
						margin: 0 auto;
					}
        }
        .cart-header {
          padding: 10px;
          border-bottom: 1px solid #898989;
        }
      }
			@media screen and (max-width: 930px) { 
				max-width: unset;
			}
    }
		@media screen and (max-width: 930px) { 
			flex-wrap: wrap;
		}
  }
  .together-products {
    border-bottom: 1px solid #898989;
    padding-bottom: 30px;
    .product-image_group {
      justify-content: flex-start;
      .plus {
        margin: 0 20px;
      }
    }
    .primary-btn {
      height: 40px;
      border-radius: 5px;
      width: auto;
      padding: 0 30px;
      margin-top: 30px;
    }
  }
}

.m-product-content {
	display: none;
}

.m-footer {
	display: none;
	.add-basket_btn {
		border-radius: 20px;
		height: 38px;
		width: auto;
		padding: 0 20px;
		font-size: 16px;
	}
}

@media screen and (max-width: 930px) { 
	.main-content-part {
		margin-bottom: 90px;
	}
	.m-product-content {
		display: block;
		.product-img {
			width: 70px;
			object-fit: contain;
		}
		.m-arrow-icon {
			position: absolute;
			right: 0;
			top: -13px;
		}
	}
	.m-footer {
		display: flex;
	}
}

  .v-application--wrap{
    min-height: 25px !important;
  }
  .text-center {
    width:fit-content;
    height:25px;
  }
  .v-btn__content {
    font-size: 20px;
  }

  .cart-num {
    position: relative;
    width: 82px;
    display: flex;
    .circle-btn {
      position: absolute;
      background-color: #fff;
      border-radius: 50%;
      cursor: pointer;
    }
    .plus-circle {
      right: 0px;
    }
    .minus-circle {
      left: 0px;
    }
    .product-num {
      background-color: #fff;
      width: 60px;
      text-align: center;
      margin: 0 12px;
      height: 24px;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
</style>